import GridGallery from "./GridGallery";
import React, {useState} from 'react';
import Modal from "./Modal.js";
import './modal.scss';
// import seafood from "../images/KCLogo.jpg";
// import fruits from "../images/ExoticFruits.jpg";
// import ffustion from "../images/Fitfusion.jpg";
// import sneakers from "../images/SneakerAssign.jpg";
// import MMail from "../images/MessageMail_Desktop.jpg";
// import MMailAcc from "../images/MM_accounts.jpg";
// import PadMockup from "../images/Pad_Mockup.jpg";
// import miabi from "../images/Miabi.jpg";
// import bgBizfront from "../images/Front.jpg";
// import bgBizback from "../images/Back.jpg";





export default function Gallery() 
{
    const [selectedImage, setSelectedImage] = useState(null);
    const images = 
    [
        "https://bradgraddesign.imgix.net/Front.jpg",
        "https://bradgraddesign.imgix.net/Back.jpg",
        "https://bradgraddesign.imgix.net/Pad_Mockup.jpg",
        "https://bradgraddesign.imgix.net/MessageMail_Desktop.jpg",
        "https://bradgraddesign.imgix.net/MM_accounts.jpg",
        "https://bradgraddesign.imgix.net/SneakerAssign.jpg",
        "https://bradgraddesign.imgix.net/Fitfusion.jpg",
        "https://bradgraddesign.imgix.net/ExoticFruits.jpg",
        "https://bradgraddesign.imgix.net/Miabi.jpg",
        "https://bradgraddesign.imgix.net/KCLogo.jpg",
       
    ];

    return(

            <section id="gallery" className="w-[85%] my-44 xs:pl-8 sm:pl-12 md:pl-24 lg:pl-24 xl:pl-24 z-2 relative">
                <div className=" text-[#EF5857] text-left font-bold text-4xl lg:text-5xl mb-8 md:text-left">Gallery</div>
                <GridGallery images={images} setSelectedImage={setSelectedImage} />
                {selectedImage && <Modal selectedImage={selectedImage} setSelectedImage={setSelectedImage}/> }
            </section>
    );
}
