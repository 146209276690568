import Logo from '../images/main_logo.svg';
import dribble from '../images/Dribble.svg';
import github from '../images/github.svg';
import instagram from '../images/Instagram.svg';
import linkedin from '../images/Linkedin.svg';
import tumblr from '../images/Tumblr.svg';
import resume from '../images/BradGradneigo_2024.pdf';


function Footer(){

    return(
        <footer className='max-w-full py-10 '>
           
                <div className='flex flex-col items-center space-y-6 md:flex-row md:space-y-0 md:justify-center md:items-center'>
                    <div className='flex flex-col items-center space-y-4 md:items-center md:space-y-2'>
                        <div className='h-8 mb-12'>
                            <a href="#hero">
                                <img src={Logo} className='w-[75px] h-[75px] md:ml-3'alt="Logo"/>
                            </a>
                        </div>
                        <div className='text-center text-white text-sm max-w-sm mt-16 xs:max-w-xs sm:max-w-sm md:text-lg md:max-w-2xl md:text-center md:mt-12 md:ml-3'>
                            Creative, tech-savvy Front End Developer with proven skills using the latest design applications
                             and knowledge of recent trends. Passionate and resourceful, seeking to gain more experience. 
                             
                        </div>
                        <div className='flex flex-row items-center font-bold text-white text-sm
                         md:flex-row md:space-y-0 md:space-x-3 md:ml-3 md:text-md'>

                            <div className='group p-4 xs:p-1 sm:p-2 '>
                                <a href="#about" className=' group-hover:border-b-2 group-hover:border-[#EF5857]' >About</a>
                                
                            </div>
                            <div className='group p-4 xs:p-1 sm:p-2'>
                                <a href="#projects" className='group-hover:border-b-2 group-hover:border-[#EF5857]'>Projects</a>
                                
                            </div>
                            <div className='group p-4 xs:p-1 sm:p-2'>
                                <a href="#gallery" className='mx-2 group-hover:border-b-2 group-hover:border-[#EF5857]'>Gallery</a>
                                
                            </div>
                            <div className='group p-4 xs:p-1 sm:p-2'>
                                <a href="#connect" className='mx-2 group-hover:border-b-2 group-hover:border-[#EF5857]'>Connect</a>
                                
                            </div>
                            <div className='group p-4 xs:p-1 sm:p-2'>
                                <a href={resume} contentType="application/pdf" target="_blank" rel="noreferrer" className='mx-2 group-hover:border-b-2 group-hover:border-[#EF5857]'>Resume</a>
                                
                            </div>

                        </div>
                        <div className='text-white md:ml-3 hover:bg-sunset-200'>
                           <a href="mailto:connect@bradgrad.design">connect@bradgrad.design</a> 
                        </div>
                        <div className='socialMedia flex flex-row items-center 
                        md:flex-row md:space-y-0 md:space-x-2 md:ml-3'>

                            <div className='group p-1'>
                                <a href="https://dribbble.com/BGrad092" >
                                    <img src={dribble} className='w-[25px] h-[25px] group-hover:bg-sunset-200'alt="dribble"/>
                                </a>
                            </div>
                            <div className='group p-1'>
                                <a href="https://github.com/fang092" >
                                    <img src={github} className='w-[25px] h-[25px] group-hover:bg-sunset-200'alt="github"/>
                                </a>
                            </div>
                            <div className='group p-1'>
                                <a href="https://instagram.com/brad_grad092" >
                                    <img src={instagram} className='w-[25px] h-[25px] group-hover:bg-sunset-200'alt="instagram"/>
                                </a>
                            </div>
                            <div className='group p-1'>
                                <a href="https://www.linkedin.com/in/bradgradneigo/" >
                                    <img src={linkedin} className='w-[25px] h-[25px] group-hover:bg-sunset-200'alt="linkedin"/>
                                </a>
                            </div>
                            <div className='group p-1'>
                                <a href="https://bradgraddesigns.tumblr.com" >
                                    <img src={tumblr} className='w-[25px] h-[25px] group-hover:bg-sunset-200'alt="tumblr"/>
                                </a>
                            </div>

                        </div>
                        <div className='text-xs text-white text-center md:ml-3'>
                            Designed & Built by Brad Gradneigo <br/>
                            Copyright © 2022 Brad Gradneigo
                        </div>

                    </div>
                </div>
        

        </footer>
    );
};
export default Footer;