import comment from "../images/Comment.svg";

function Contact(){


    return(
        <section id="connect" className="container flex flex-col max-w-6xl mx-auto my-32 px-6 items-center  md:items-center">
            <div className="max-w-md text-[#EF5857]  text-center font-bold font-raleway text-4xl mb-6  md:text-left "> Get In Touch</div>
            <div className="max-w-lg text-white font-merriweather text-2xl text-center">I am looking for new opportunities as a Front End Developer! My inbox is always open,
                 and I’ll do my best to get back to you quickly. 
             </div>
             <a href="mailto:bradleyg36@gmail.com" className='flex flex-row items-center text-xl ml-0 font-bold max-w-sm
                                mt-6 py-2 px-4 rounded-[5px] text-white border-4  duration-300 bg-sunset-100 border-sunset-100
                                hover:bg-sunset-200 hover:border-sunset-200 hover:text-white hover:duration-300 md:text-2xl md:max-w-sm '>
                    Say Hello <img src={comment} className=" ml-1 w-[45px] h-[45px]" alt="button arrow"/>
            </a>
        </section>

    );

}
export default Contact;