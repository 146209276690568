
import './projects.scss';



function Projects(){

    return(
        <section id="projects" className=' w-[85%] my-44 xs:pl-8 sm:pl-12 md:pl-24 lg:pl-24 xl:pl-24 z-2 relative'>

            <h2 className="w-full text-[#EF5857] text-left text-4xl font-bold lg:text-5xl mb-8">Projects</h2>

            <div className='grid grid-cols-3 gap-3 w-full xs:grid-cols-1 sm:grid-cols-1  md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'>
                
                   
                <a href="https://clipboard-lemon.vercel.app"
                    target="_blank"
                    rel="noreferrer"
                    className='projectContainer'>

                    <div className='group project'>

                        <div className="projectDetails">
                            <h5 className=' projectName '>
                                Clipboard.io
                            </h5>
                            <p className=' projectDescription '> 
                                A small follow along project, converted to a React Project with TailwindCSS.
                                Orginally a project from Udemy course by Traversity Media.
                            </p>
                            <div className='projectTags'>
                                <div className="tags">
                                    <span className='tailwindcss'>TailwindCSS</span>
                                    <span className='reactJS'>ReactJS</span>
                                    <span className='html'>HTML</span>
                                </div>    
                            </div>
                        </div>
                       
                    </div> 
                </a>

               
                 
                <a href="https://pkmnserver.vercel.app"
                    target="_blank"
                    rel="noreferrer"
                    className='projectContainer'>

                    <div className='group project'>
                            <div className="projectDetails">
                                <h5 className='projectName'>
                                    PokeCodex
                                </h5>
                                <p className=' projectDescription '> 
                                    A Pokemon API App, learning how to make calls to an API and update the UI
                                    based on the information retrieved.
                                </p>
                                <div className='projectTags'>
                                    <div className="tags">
                                        <span className='tailwindcss'>TailwindCSS</span>
                                        <span className='pokemonAPI'>PokemonAPI</span>
                                        <span className='reactJS'>ReactJS</span>
                                        <span className='html'>HTML</span>
                                        
                                    </div>
                                </div>
                            </div>
                       
                    </div> 
                </a>

                <a href="https://cprint-v1.vercel.app"
                    target="_blank"
                    rel="noreferrer"
                    className='projectContainer'>

                    <div className='group project'>
                            <div className="projectDetails">
                                <h5 className='projectName'>
                                    C-Print
                                </h5>
                                <p className=' projectDescription '> 
                                    A old idea centered around a comic oriented printing service from college remade into a website
                                </p>
                                <div className='projectTags'>
                                    <div className="tags">
                                        <span className='tailwindcss'>TailwindCSS</span>
                                        <span className='html'>HTML</span>
                                        <span className='reactJS'>ReactJS</span>
                                       
                                    </div>
                                </div>
                            </div>
                       
                    </div> 
                </a>


                <a href="https://recipebox.vercel.app/"
                    target="_blank"
                    rel="noreferrer"
                    className='projectContainer'>

                    <div className='group project'>
                            <div className="projectDetails">
                                <h5 className='projectName'>
                                    RecipeNook
                                </h5>
                                <p className=' projectDescription '> 
                                    A recipe blog made using Contentful, Contentful API, TailwindCSS, and ChakraUI.
                                </p>
                                <div className='projectTags'>
                                    <div className="tags">
                                        <span className='tailwindcss'>TailwindCSS</span>
                                        <span className='html'>HTML</span>
                                        <span className='reactJS'>ReactJS</span>
                                        <span className="chakraUI">ChakraUI</span>
                                        <span className="contentful">Contentful</span>
                                        <span className='ongoing'>Ongoing</span>
                                        
                                    </div>
                                </div>
                            </div>
                       
                    </div> 
                </a>
                
                <a href="https://la-mountains-mu.vercel.app/"
                    target="_blank"
                    rel="noreferrer"
                    className='projectContainer'>

                    <div className='group project'>
                            <div className="projectDetails">
                                <h5 className='projectName'>
                                    LA Mountains
                                </h5>
                                <p className=' projectDescription '> 
                                    A skill test project that I coded within a weekend.  
                                </p>
                                <div className='projectTags'>
                                    <div className="tags">
                                        <span className='tailwindcss'>TailwindCSS</span>
                                        <span className='html'>HTML</span>
                                        <span className='reactJS'>ReactJS</span>
                                        <span className="chakraUI">ChakraUI</span>
                                       
                                    </div>
                                </div>
                            </div>
                       
                    </div> 
                </a>


                {/* Templates for projects */}

                {/* <a href="https://pkmnserver.vercel.app"
                    target="_blank"
                    rel="noreferrer"
                    className='projectContainer '>

                    <div className='group project'>
                            <div className="projectDetails">
                                <h5 className='projectName'>
                                    PokeCodex
                                </h5>
                                <p className=' projectDescription '> 
                                    A Pokemon API app, very basic right now. It is my current ongoiong project.
                                </p>
                                <div className='projectTags'>
                                    <div className="tags">
                                        <span className='tailwindcss'>Tailwindcss</span>
                                        <span className='PokemonAPI'>PokemonAPI</span>
                                        <span className='ReactJS'>ReactJS</span>
                                    </div>
                                    <div className="tags">
                                        <span className='HTML'>HTML</span>
                                        <span className='CSS'>CSS</span>
                                        <span className='ongoing'>Ongoing</span>
                                    </div>
                                </div>
                            </div>
                       
                    </div> 
                </a>
                <a href="https://pkmnserver.vercel.app"
                    target="_blank"
                    rel="noreferrer"
                    className='projectContainer '>

                    <div className='group project'>
                            <div className="projectDetails">
                                <h5 className='projectName'>
                                    PokeCodex
                                </h5>
                                <p className=' projectDescription '> 
                                    A Pokemon API app, very basic right now. It is my current ongoiong project.
                                </p>
                                <div className='projectTags'>
                                    <div className="tags">
                                        <span className='tailwindcss'>Tailwindcss</span>
                                        <span className='PokemonAPI'>PokemonAPI</span>
                                        <span className='ReactJS'>ReactJS</span>
                                    </div>
                                    <div className="tags">
                                        <span className='HTML'>HTML</span>
                                        <span className='CSS'>CSS</span>
                                        <span className='ongoing'>Ongoing</span>
                                    </div>
                                </div>
                            </div>
                       
                    </div> 
                </a> */}

                
               
                

                

                
            </div>
           
        </section>
    );
            
        

};
export default Projects;