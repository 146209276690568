import { useState } from "react";
import React from 'react';
import Imgix from "react-imgix";
import Atropos from 'atropos/react';
import 'atropos/css'
import ScrollReveal from 'scrollreveal'






export default function GridGallery({images ,setSelectedImage}){
    const [imagesShownArray, setImagesShownArray] = useState(
        Array(images.length).fill(false)
    );
    
   
    return(
        <div className= "grid grid-cols-4 gap-3">
            {images && images.map((imageUrl, index)=> (
                
                <GridGalleryCard
                  key={index}
                  imageUrl={imageUrl}
                  show={imagesShownArray[index]}
                  setSelectedImage={setSelectedImage}
                />
              
            ))}
        </div>
    );

};
// function Tilt(props){
//     const {options, ...rest} = props;
//     const tilt = useRef(null);

//     useEffect(()=>{
//         VanillaTilt.init(tilt.current, options);
//     }, [options]);

//     return <div ref={tilt} {...rest} />
// }
     
function GridGalleryCard({ imageUrl , show , setSelectedImage}) 
{
   
    /* 
  {
    reverse:           false,  // reverse the tilt direction
    max:               35,     // max tilt rotation (degrees)
    perspective:       1000,   // Transform perspective, the lower the more extreme the tilt gets.
    scale:             1,      // 2 = 200%, 1.5 = 150%, etc..
    speed:             300,    // Speed of the enter/exit transition
    transition:        true,   // Set a transition on enter/exit.
    axis:              null,   // What axis should be disabled. Can be X or Y.
    reset:             true,   // If the tilt effect has to be reset on exit.
    easing:            "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
    glare:             false,   // if it should have a "glare" effect
    "max-glare":       1,      // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
    "glare-prerender": false   // false = VanillaTilt creates the glare elements for you, otherwise
                               // you need to add .js-tilt-glare>.js-tilt-glare-inner by yourself
} */
    // const options = {
    //     scale: 1,
    //     speed: 300,
    //     max: 30,
    //     "full-page-listening":false,
    //     "mouse-event-element":null,
        
    //   };
    ScrollReveal().reveal('.galleryCard', { interval: 32, reset: true});
    return (
                // <Tilt options={options} className={` relative ${
                //     show ? "" : ""}`} 
                //     onClick={() => setSelectedImage(imageUrl)}
                //     style={{overflow:"hidden",height:"0", padding:"50% 50%",position:"relative",zIndex:'10'}}
                // >


                    /* <div className="absolute inset-0 z-10 flex transition duration-200 ease-in" >
                        <div className="absolute inset-0"></div>
                        <div className="mx-auto text-white z-10 self-center uppercase tracking-widest text-sm">
                        </div>
                    </div> */
                    <Atropos onClick={() => setSelectedImage(imageUrl)}>
                        <Imgix className="galleryCard"  src={imageUrl} sizes="25vw" width={500} height={500} imgixParams={{ fit: "crop", ar: "1:1" , crop:"entropy"}}/>
                    </Atropos>
                 
                  
                   
       
    );
}