import './main.scss';
import arrow from '../images/rightarrow.svg';
// import cover from "../images/cover2.svg";



function Main(){

    return(
        <section id="hero" className=' flex flex-row w-full  xs:my-16 sm:my-16 md:my-32 lg:my-[145px] z-2 relative' >
        
            <div id="blurb" className='w-full pb-10  xs:pl-8 sm:pl-12 md:pl-24 lg:pl-24 xl:pl-24 '>
                <p id="hi">Hi, I am</p>
                <span id="myName">Brad Gradneigo</span>
                <p id="whatIdo">Front-End Developer and UX Specialist/Designer </p>
               
                <a href="#projects" className='checkoutmywork'>
                    Check Out My Work <img src={arrow} className="w-[45px] h-[45px]" alt="button arrow"/>
                </a>
            </div>
            {/* <img src={cover} alt="" className='w-1/2 bg-indigo-500'/> */}
        </section>
    );
            
        

};
export default Main;