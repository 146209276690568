import './about.scss';



function About(){
    return(

        <section id="about" className='flex flex-row  w-[85%] my-44 xs:flex-col  xs:pl-8 sm:pl-12 md:pl-24 lg:pl-24 xl:pl-24 z-2 relative '>

            <h2 id="aboutMe">About Me</h2>
                
            <div id="introduction">

                <img src={"https://bradgraddesign.imgix.net/Brad-Gradneigo1s.jpg"} className='w-90 h-50 bg-red-500 place-self-center rounded-lg xs:w-72 xs:h-50 sm:w-72 sm:h-50 md:w-72 md:h-50 lg:w-72 lg:h-50 xl:w-72 xl:h-50 xs:place-self-start sm:place-self-start md:place-self-center lg:place-self-center xl:place-self-center ' alt="pic of me"/>

                <div className= " text-white font-normal font-merriweather text-lg  h-fit xs:w-full sm:w-full md:w-full md:text-lg lg:w-full lg:text-xl xl:w-3/4 xl:text-xl ">
                    A design first creative that specializes in web development. 
                    Currently looking for a role in the front-end web world.


                        <p className=" text-white font-normal text-xl mt-3 mb-3">
                            My core languages and stack:
                        </p>
                    <div className='flex flex-col  xs:flex-row xs:space-x-8'>
                        <span className=" text-white font-normal text-xl">
                            <ul>
                                <li><span className='text-[#74B2E1] font-bold'>></span> React</li>
                                <li><span className='text-[#74B2E1] font-bold'>></span> HTML</li>
                                <li><span className='text-[#74B2E1] font-bold'>></span> CSS/SASS</li>
                            </ul>
                        </span>
                        <span className=" text-white font-normal text-xl ">
                            <ul>
                                <li><span className='text-[#74B2E1] font-bold'>></span> Tailwind</li>
                                <li><span className='text-[#74B2E1] font-bold'>></span> Figma</li>
                                <li><span className='text-[#74B2E1] font-bold'>></span> PS & AI</li>
                            </ul>
                        </span>
                    </div>
                    
                </div>        
            </div>    
          
        </section>
    );
};
export default About;