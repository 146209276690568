import React from "react";
import './navbar.scss';
import logo from '../images/main_logo.svg';
import resume from "../images/BradGradneigo_2024.pdf";



function Navbar(){
    const navRef=React.useRef(null);
    const menuClickRef=React.useRef(null);
   

    const toggleMenu = event=>{
        event.currentTarget.classList.toggle('open');
        navRef.current.classList.toggle("hidden");
   }
   const closeMenu = happening =>{

        menuClickRef.current.classList.toggle('open');
        navRef.current.classList.toggle("hidden");

   }
   const openResume = clickResume =>{

        window.open(resume);
   }
    return (
        
        <nav id="navbar" className='flex items-center justify-between px-6 font-bold text-white mt-4 z-10 relative'>
            
            <a href="#hero">
                <img className='logo' src={logo} alt="logo"></img>
            </a>
            
            <div className="hidden h-10 pr-5 md:flex md:space-x-8 items-center ">
                
                    <div className='group'>
                        <a className="p-2 shrink font-bold group-hover:border-b-2 group-hover:border-[#EF5857]" href="#home">Home</a>
                    </div>
                    <div className='group'>
                        <a className="p-2 shrink font-bold group-hover:border-b-2 group-hover:border-[#EF5857]" href="#about">About</a>
                    </div>
                    <div className='group'>
                        <a className="p-2 shrink font-bold group-hover:border-b-2 group-hover:border-[#EF5857]" href="#projects">Projects</a>
                    </div>
                    <div className='group'>
                        <a className="p-2 shrink font-bold group-hover:border-b-2 group-hover:border-[#EF5857]" href="#gallery">Gallery</a>
                    </div>
                    <div className='group'>
                        <a className="p-2 shrink font-bold group-hover:border-b-2 group-hover:border-[#EF5857]" href="#connect">Connect</a>
                    </div>
                   
                    
                    <button onClick={openResume} className="p-2 shrink font-bold mx-2 border-2 border-white duration-300 hover:bg-[#EF5857] hover:duration-300">
                        Resume
                    </button>

            </div>
            <div className='md:hidden'>
                <button id="menu-btn" type="button" ref={menuClickRef} onClick={toggleMenu} className='z-20 block hamburger md:hidden focus:outline-none'>
                    <span className="hamburger-top"></span>
                    <span className="hamburger-mid"></span>
                    <span className="hamburger-bot"></span>
                </button>
            </div>
            <div id="menu" ref={navRef} className='hidden absolute -top-5 bottom-0 left-0 z-24  flex flex-col self-end w-full min-h-screen py-1 pt-40 pl-12 space-y-3 text-lg text-white bg-black'>
                <a href="#about" onClick={closeMenu} className="hover:text-[#EF5857]">About</a>
                <a href="#projects" onClick={closeMenu} className="hover:text-[#EF5857]">Projects</a>
                <a href="#gallery" onClick={closeMenu} className="hover:text-[#EF5857]">Gallery</a>
                <a href="#connect" onClick={closeMenu} className="hover:text-[#EF5857]">Connect</a>
                <a href={resume} target="_blank" rel="noreferrer" onClick={closeMenu} className="hover:text-[#EF5857]">Resume</a>
            </div>
            
        </nav>
       

      


    );

};
export default Navbar;