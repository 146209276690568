// import React from 'react';

import React,{useState, useRef, useEffect} from 'react'
import TRUNK from 'vanta/dist/vanta.trunk.min.js'
import p5 from 'p5'
import {Helmet} from "react-helmet";
import './App.css';
import Navbar from './components/Navbar';
import Main from './components/Main';
import About from './components/About';
import Projects from './components/Projects';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import banner from './images/Banner.svg';




function App() {

  
  //ReactGA.initialize("G-ZJZJ8N2EH0");


    const[vantaEffectA, setVantaEffectA] = useState(0);
    const vantaRefA = useRef(null);
    const[vantaEffectB, setVantaEffectB] = useState(0);
    const vantaRefB = useRef(null);
    p5.disableFriendlyErrors = true;

    useEffect(()=>{
      const threeScript = document.createElement("script")
      threeScript.setAttribute("id", "threeScript")
      threeScript.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/three.js/0.145.0/three.min.js")
      console.log(document.getElementsByTagName("head")[0].appendChild(threeScript))

        if(!vantaEffectA){
          setVantaEffectA(
            TRUNK(({
              el:vantaRefA.current,
              mouseControls: true,
              touchControls: true,
              gyroControls: false,
              minHeight: 100.00,
              minWidth: 100.00,
              scale:5,
              scaleMobile: 1.00,
              color: '#A73D3C',
              backgroundColor: '0',
              spacing: 6,
              chaos:1,
              p5:p5
            
            }))
            )
        }
        if(!vantaEffectB){
          setVantaEffectB(
            TRUNK(({
              el:vantaRefB.current,
              mouseControls: true,
              touchControls: true,
              gyroControls: false,
              minHeight: 100.00,
              minWidth: 100.00,
              scale:5,
              scaleMobile: 1.00,
              color: '#75a689',
              backgroundColor: '0',
              spacing: 6,
              chaos:1.2,
              p5:p5
            
            }))
            )
        }
       return () => {
          if(threeScript) threeScript.remove()
          if(vantaEffectA) vantaEffectA.destory()
          if(vantaEffectB) vantaEffectB.destory()
       }
   }, [vantaEffectA, vantaEffectB]);


  
  return(
    
    <div className="background relative overflow-x-hidden">
        
        <Helmet>
            <title>BradGradDesign</title>
            <meta name="image" content={banner} />
            <meta name="description" content="A gritty front-end web develper who combines his art skills with his passion to further himself" />
        </Helmet>
    
      
      <div  className="container max-w-7xl   mx-auto h-fit py-1 relative z-0 ">
        <div className="TrunkA" ref={vantaRefA} />
        <div className="TrunkB" ref={vantaRefB} /> 
       
        <Navbar/>
        <Main/>
        <About/>
        <Projects/>
        <Gallery/>
        <Contact/>
        <Footer/>
      </div>
      
      
    </div>
  );
}

export default App;
